:root {
  --primary-color: #002060;
  --secondary-color: #2ecc71;
  --text-color: #333;
}

/* General Styles */
h2 {
  color: var(--primary-color);
  margin-bottom: 0.5rem;
  font-weight: 400;
}

h3 {
  color: white;
  font-weight: 550;
}

a {
  text-decoration: none;
}

p {
  font-weight: 200;
}

li {
  font-weight: 200;
}

.content-wrapper {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 2rem;
}

.about-details {
  display: flex;
  align-items: center;
}

.headshot-image {
  height: 100px;
  width: 100px;
  object-fit: cover;
}

.source-link:hover {
  color: darkgray;
}

/* Header Styles */
.header-wrapper {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: var(--primary-color);
  color: white;
}

.menu-toggle {
  display: none;
}

.nav {
  display: flex;
  gap: 1rem;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.nav span,
.nav a {
  font-weight: 400;
  background-color: var(--primary-color);
  color: white;
  padding: 0.5rem 1rem;
  text-align: center;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.3s ease;
}

.nav span:hover,
.nav a:hover{
  border-bottom: 2px solid white;
}

.nav span.active {
  font-weight: bold;
  border-bottom: 2px solid white; /* Customize the active indicator */
}

.logo-div {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 40px;
  width: 40px;
  object-fit: cover;
  
}

.logo {
  font-weight: 400;
  font-size: 1.5rem;
  color: white;
  transition: 0.3s ease;
  cursor: pointer;
}

.logo h2{
  font-weight: 400;
  font-size: 1.5rem;
  color: white;
  margin: 0.5rem !important;
  transition: 0.3s ease;
}

.logo-div:hover {
  border-bottom: 2px solid white;
}

/* Footer Styles */
.footer-container {
  color: var(--primary-color);
  padding: 1rem;
  text-align: center;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.social-links a {
  color: #002060;
  transition: transform 0.3s ease;
}

.social-links a:hover {
  transform: scale(1.1);
}

/* Small Screen Styles */
@media screen and (max-width: 768px) {
  .menu-toggle {
    display: block;
    color: white;
    background-color: var(--primary-color);
    border: none;
    display: flex;
  }

  .nav {
    display: none;
    flex-direction: column;
    gap: 0.5rem;
    background-color: var(--primary-color);
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    padding: 1rem;
    border-top: 1px solid white;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
  }

  .nav.open {
    display: flex;
    opacity: 1;
    max-height: 500px; /* Ensures smooth dropdown animation */
  }

  .timeline-content {
    position: relative;
    margin-left: 15px!important;
    background-color: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
}

/* Timeline Styles */
.section {
  margin-bottom: 2rem;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.timeline-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

.timeline-wrapper::before {
  content: "";
  position: absolute;
  left: 15px;
  top: 0;
  width: 5px;
  height: 100%;
  background-color: var(--primary-color); 
}

.timeline-item {
  padding-bottom: 40px;
  display: flex;
}

.timeline-item-icon {
  position: relative;
  top: 20px;
  left: -5px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

.timeline-content {
  position: relative;
  margin-left: 40px;
  margin-top: 20px;
  background-color: var(--primary-color);
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: -webkit-fill-available;
}

.timeline-content h3 {
  margin-top: 0;
}

.timeline-item:last-child {
  margin-bottom: 0;
}

.timeline-item p {
  color: white;
}

.timeline-item li {
  color: white;
}

.full-width-btn:hover {
  color: darkgray;
}

.full-width-btn {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.project-preview-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: top;
  margin-bottom: 25px;
}

.source-link {
  text-decoration: underline;
  color: white;
  transition: color 0.3s ease;
}

.project-info-header {
  margin-bottom: 10px;
  margin-top: 25px!important;
}

.button-icon {
  color: white;
}

.button-icon:hover {
  color: darkgray;
}